import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Requote.css';
import whiteBlackSpotsImg from '../../assets/images/errorVariation/screenSpotErrors/whiteBlackSpots.png';
import differentColorSpotsImg from '../../assets/images/errorVariation/screenSpotErrors/differentColorSpots.png';
import yellowSpotsImg from '../../assets/images/errorVariation/screenSpotErrors/yellowSpots.png';
import noDefectImg from '../../assets/images/errorVariation/screenSpotErrors/noDefect.png';
import singleLineonDisplayImg from '../../assets/images/errorVariation/lineErrors/singleLineonDisplay.png';
import multipleLineonDisplayImg from '../../assets/images/errorVariation/lineErrors/multipleLineonDisplay.png';
import frontGlassCrackedImg from '../../assets/images/errorVariation/screenPhysicalCondition/frontGlassCracked.png';
import chippedCrackedImg from '../../assets/images/errorVariation/screenPhysicalCondition/chippedCracked.png';
import heavyScratches from '../../assets/images/errorVariation/screenPhysicalCondition/heavyScratches.png';
import OneAndTwoScratches from '../../assets/images/errorVariation/screenPhysicalCondition/OneAndTwoScratches.png';
import MajorScratches from '../../assets/images/errorVariation/bodyErrors/MajorScratches.png';
import oneAndTwoDentsImg from '../../assets/images/errorVariation/dentsonBody/oneAndTwoDents.png';
import HeavyDentsAndCrackedImg from '../../assets/images/errorVariation/dentsonBody/HeavyDentsAndCracked.png';
import crackedBrokenSideORBackPanelImg from '../../assets/images/errorVariation/panelErrors/crackedBrokenSideORBackPanel.png';
import missingSideORBackPanelImg from '../../assets/images/errorVariation/panelErrors/missingSideORBackPanel.png';
import BodyBendORDeformImg from '../../assets/images/errorVariation/bendErrors/BodyBendORDeform.png';
import looseGapScreenImg from '../../assets/images/errorVariation/bendErrors/looseGapScreen.png';
import frontCameraFaultyImg from '../../assets/images/errorVariation/functionalErrors/frontCameraFaulty.png';
import backCameraFaultyImg from '../../assets/images/errorVariation/functionalErrors/backCameraFaulty.png';
import volumeButtonFaultyImg from '../../assets/images/errorVariation/functionalErrors/volumeButtonFaulty.png';
import fingerSensorFaultyImg from '../../assets/images/errorVariation/functionalErrors/fingerSensorFaulty.png';
import wifiFaultyImg from '../../assets/images/errorVariation/functionalErrors/wifiFaulty.png';
import batteryFaultyImg from '../../assets/images/errorVariation/functionalErrors/batteryFaulty.png';
import speakerFaultyImg from '../../assets/images/errorVariation/functionalErrors/speakerFaulty.png';
import powerButtonFaultyImg from '../../assets/images/errorVariation/functionalErrors/powerButtonFaulty.png';
import audioReceiverFaultyImg from '../../assets/images/errorVariation/functionalErrors/audioReceiverFaulty.png';
import charginFaultyImg from '../../assets/images/errorVariation/functionalErrors/charginFaulty.png';
import faceSensorFaultyImg from '../../assets/images/errorVariation/functionalErrors/faceSensorFaulty.png';
import originalBoxImg from '../../assets/images/errorVariation/accessories/originalBox.png';
import originalchargerImg from '../../assets/images/errorVariation/accessories/originalcharger.png';
import originalEarphonesImg from '../../assets/images/errorVariation/accessories/originalEarphones.png';
import warranty03Img from '../../assets/images/errorVariation/warranty/0-3MonthWaranty.png';
import warranty36Img from '../../assets/images/errorVariation/warranty/3-6MonthWaranty.png';
import warranty611Img from '../../assets/images/errorVariation/warranty/6-11MonthWaranty.png';
import warrantyAbove11Img from '../../assets/images/errorVariation/warranty/Above11MonthWaranty.png';
import { fetchSelectedMobileVariant, getReExactValue } from '../../utils/api';
import { modelImagePath } from '../../utils/constants';
import Loader from '../../components/loader/loader';
import toast from 'react-hot-toast';
function Requote() {
    const { ref_no, model, variant } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [variantData, setVariantData] = useState(null);
    const [modelData, setModelData] = useState(null);
    const [step, setStep] = useState(1);
    const [receiveCallsWork, setReceiveCallsWork] = useState('');
    const [screenWorkingProperly, setScreenWorkingProperly] = useState('');
    const [phoneBodyProperly, setphoneBodyProperly] = useState('');
    const [phoneUnderWarranty, setphoneUnderWarranty] = useState('');
    const [touchScreen, setTouchScreen] = useState('');
    const [screenspoterror, setScreenSpotError] = useState(null);
    const [screenlineerror, setScreenLineError] = useState(null);
    const [screenphysicalcondition, setScreenPhysicalCondition] = useState(null);
    const [bodyscratches, setBodyScratches] = useState(null);
    const [bodydent, setBodyDent] = useState(null);
    const [panelerror, setPanelError] = useState(null);
    const [benderror, setBendError] = useState(null);

    const handleOptionChangeReceiveCallsWork = (event) => {
        setReceiveCallsWork(event.target.value);
    };
    const handleOptionChangeScreenWorkingProperly = (event) => {
        setScreenWorkingProperly(event.target.value);
    };
    const handleOptionChangephoneBodyProperly = (event) => {
        setphoneBodyProperly(event.target.value);
    };
    const handleOptionChangePhoneUnderWarranty = (event) => {
        setphoneUnderWarranty(event.target.value);
    };
    const handleOptionChangeTouchScreen = (event) => {
        setTouchScreen(event.target.value);
    };
    const handleOptionChangeScreenSpotError = (event) => {
        setScreenSpotError(event.target.value);
    };
    const handleOptionChangeScreenLineError = (event) => {
        setScreenLineError(event.target.value);
    };
    const handleOptionChangeScreenPhysicalCondition = (event) => {
        setScreenPhysicalCondition(event.target.value);
    };
    const handleOptionChangeBodyScratches = (event) => {
        setBodyScratches(event.target.value);
    };
    const handleOptionChangeBodyDent = (event) => {
        setBodyDent(event.target.value);
    };
    const handleOptionChangePanelError = (event) => {
        setPanelError(event.target.value);
    };
    const handleOptionChangeBendError = (event) => {
        setBendError(event.target.value);
    };



    const [functionalerrorList, setFunctionalErrorList] = useState({
        F1: false,
        F2: false,
        Vbutton: false,
        FTouch: false,
        wifi: false,
        Vbutton: false,
        Battery: false,
        Spk: false,
        PwBut: false,
        AudRcv: false,
        chgPort: false,
        FcSnsr: false
    });


    const [functionalerror, setFunctionalError] = useState([]);

    const handleChangeFunctionalError = (event) => {
        const { name, checked } = event.target;

        setFunctionalErrorList({
            ...functionalerrorList,
            [name]: checked
        });

        if (checked) {
            setFunctionalError([...functionalerror, name]);
        } else {
            setFunctionalError(functionalerror.filter(value => value !== name));
        }
    };

    //Box: 2, Chr: 1, EarPhone: 0
    const [accessoriesList, setAccessoriesList] = useState({
        Box: false,
        Chr: false,
        EarPhone: false
    });

    const [accessories, setAccessories] = useState([]);

    const handleChangeAccessories = (event) => {
        const { name, checked } = event.target;

        setAccessoriesList({
            ...accessoriesList,
            [name]: checked
        });

        if (checked) {
            setAccessories([...accessories, name]);
        } else {
            setAccessories(accessories.filter(value => value !== name));
        }
    };

    const [warranty, setWarranty] = useState(null);
    const handleOptionChangeWarranty = (event) => {
        setWarranty(event.target.value);
    };


    const nextStep = () => {
        if (receiveCallsWork != '' && screenWorkingProperly != '' && phoneBodyProperly != '' && phoneUnderWarranty != '') {
            if (step == 1) {
                if (screenWorkingProperly === 'no') {
                    setStep(2);
                } else if (phoneBodyProperly === 'no') {
                    setStep(3)
                } else {
                    setStep(4)
                }
            }
            if (step == 2) {
                if (phoneBodyProperly === 'no') {
                    setStep(3);
                } else {
                    setStep(4);
                }
            }
            if (step == 3) {
                setStep(4);
            }
            if (step == 4) {
                setStep(5)
            }
            if (step == 5) {
                if (phoneUnderWarranty === 'yes') {
                    setStep(6)
                }
            }
        }
        else {
            toast.error("Please check all Properly");
        }

    };

    const prevStep = () => {

        if (step == 2) {
            setStep(1);
        }
        if (step == 3) {
            if (screenWorkingProperly === 'no') {
                setStep(2);
            } else {
                setStep(1);
            }
        }
        if (step == 4) {
            if (phoneBodyProperly === 'no') {
                setStep(3);
            } else if (screenWorkingProperly === 'no') {
                setStep(2);
            } else {
                setStep(1);
            }
        }
        if (step == 5) {
            setStep(4);
        }
        if (step == 6) {
            setStep(5);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // Handle form submission
        let ob = {
            "callingerr": receiveCallsWork,
            "toucherr": touchScreen,
            "screenspoterror": screenspoterror,
            "screenlineerror": screenlineerror,
            "screenphysicalcondition": screenphysicalcondition,
            "bodyscratches": bodyscratches,
            "bodydent": bodydent,
            "panelerror": panelerror,
            "benderror": benderror,
            "functionalerror": functionalerror,
            "accessories": accessories,
            "warranty": warranty,
            "variant": variant,
            "ic_slug": model,
            "item_name": `${modelData?.ItemName} (${variantData?.VariantName})`,
            "model_name": modelData?.ItemName,
            "variant_name": variantData?.VariantName,
            "item_image": modelImagePath + modelData?.ic_slug + '.webp',
            "ref_no": ref_no
        };

        try {
            const response = await getReExactValue(ob);
            navigate(`/reexact-value/` + ref_no);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching sell mobile data:', error);
            setLoading(false);
        }


    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchSelectedMobileVariant(model, variant);
                setVariantData(response?.variant);
                setModelData(response?.model);
                setLoading(false);
            } catch (error) {
                // Handle error
                console.error('Error fetching sell mobile data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <div>
            {loading && <Loader />}
            <section className="about-section section-padding" id="section_2">

                <div className="container">
                    <div className='row justify-content-center'>

                        <div className="col-lg-9">
                            <div className="custom-block">
                                <form onSubmit={handleSubmit} className="form">
                                    <div className="custom-block-info">
                                        <h4 className="mb-5">
                                            {modelData?.ItemName} ({variantData?.VariantName})
                                        </h4>
                                        {step === 1 && (
                                            <div className="form-step">
                                                <h6 className="mb-2">
                                                    Primary Info
                                                </h6>
                                                <div className='features'>
                                                    <p className="mb-0"><b>1. Can you able to make and receive calls?</b></p>
                                                    <p className="mb-0">Check your device network connectivity Status.</p>
                                                    <div className="main-container">
                                                        <div className="radio-buttons text-left">
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="receiveCallsWork"
                                                                    value="yes"
                                                                    checked={receiveCallsWork === 'yes'}
                                                                    onChange={handleOptionChangeReceiveCallsWork}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">Yes</h3>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="receiveCallsWork"
                                                                    value="no"
                                                                    checked={receiveCallsWork === 'no'}
                                                                    onChange={handleOptionChangeReceiveCallsWork}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">No</h3>
                                                                    </div>
                                                                </span>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-0"><b>2. Is the Mobile screen in good condition?</b></p>
                                                    <p className="mb-0">Check your Phone screen for cracks , scratches, Fading spots, lines or touch problems.</p>
                                                    <div className="main-container">
                                                        <div className="radio-buttons text-left">
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="screenWorkingProperly"
                                                                    value="yes"
                                                                    checked={screenWorkingProperly === 'yes'}
                                                                    onChange={handleOptionChangeScreenWorkingProperly}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">Yes</h3>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="screenWorkingProperly"
                                                                    value="no"
                                                                    checked={screenWorkingProperly === 'no'}
                                                                    onChange={handleOptionChangeScreenWorkingProperly}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">No</h3>
                                                                    </div>
                                                                </span>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-0"><b>3. Is the Phone body in good condition?</b></p>
                                                    <p className="mb-0">Check your Phone body for Bents  Dents  Scratches.</p>
                                                    <div className="main-container">
                                                        <div className="radio-buttons text-left">
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="phoneBodyProperly"
                                                                    value="yes"
                                                                    checked={phoneBodyProperly === 'yes'}
                                                                    onChange={handleOptionChangephoneBodyProperly}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">Yes</h3>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="phoneBodyProperly"
                                                                    value="no"
                                                                    checked={phoneBodyProperly === 'no'}
                                                                    onChange={handleOptionChangephoneBodyProperly}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">No</h3>
                                                                    </div>
                                                                </span>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-0"><b>4. Is Phone Under Warranty?</b></p>
                                                    <p className="mb-0">Provide valid Bill for warranty.</p>
                                                    <div className="main-container">
                                                        <div className="radio-buttons text-left">
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="phoneUnderWarranty"
                                                                    value="yes"
                                                                    checked={phoneUnderWarranty === 'yes'}
                                                                    onChange={handleOptionChangePhoneUnderWarranty}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">Yes</h3>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="phoneUnderWarranty"
                                                                    value="no"
                                                                    checked={phoneUnderWarranty === 'no'}
                                                                    onChange={handleOptionChangePhoneUnderWarranty}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">No</h3>
                                                                    </div>
                                                                </span>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <div className="mt-2 text-center">
                                                        <button className="btn custom-btn" onClick={nextStep} disabled={!(receiveCallsWork && screenWorkingProperly && phoneBodyProperly && phoneUnderWarranty)}
                                                        >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {step === 2 && (
                                            <div className="form-step">
                                                <h6 className="mb-2">
                                                    Screen Errors
                                                </h6>
                                                <div className='features'>
                                                    <p className="mb-0"><b>Is Touch screen Working?</b></p>
                                                    <p className="mb-0">Please check Screen Touch.</p>
                                                    <div className="main-container">
                                                        <div className="radio-buttons text-left">
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="touchScreen"
                                                                    value="yes"
                                                                    checked={touchScreen === 'yes'}
                                                                    onChange={handleOptionChangeTouchScreen}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">Yes</h3>
                                                                    </div>
                                                                </span>
                                                            </label>
                                                            <label className="custom-radio">
                                                                <input
                                                                    type="radio"
                                                                    name="touchScreen"
                                                                    value="no"
                                                                    checked={touchScreen === 'no'}
                                                                    onChange={handleOptionChangeTouchScreen}
                                                                />
                                                                <span className="radio-btn">
                                                                    <div className="hobbies-icon">
                                                                        <h3 className="">No</h3>
                                                                    </div>
                                                                </span>
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='features mobile'>
                                                    <p className="mb-2"><b> Screen Spot Errors</b></p>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenspoterror"
                                                                        value="Sp1"
                                                                        checked={screenspoterror === 'Sp1'}
                                                                        onChange={handleOptionChangeScreenSpotError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={whiteBlackSpotsImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>White / Black Spots</p>
                                                                    </span>
                                                                </label>
                                                            </div>

                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenspoterror"
                                                                        value="Sp2"
                                                                        checked={screenspoterror === 'Sp2'}
                                                                        onChange={handleOptionChangeScreenSpotError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={differentColorSpotsImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Different Color Spots</p>
                                                                    </span>
                                                                </label>
                                                            </div>

                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenspoterror"
                                                                        value="Sp3"
                                                                        checked={screenspoterror === 'Sp3'}
                                                                        onChange={handleOptionChangeScreenSpotError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={yellowSpotsImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Yellow Spots</p>
                                                                    </span>
                                                                </label>
                                                            </div>

                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenspoterror"
                                                                        value=""
                                                                        checked={screenspoterror === ''}
                                                                        onChange={handleOptionChangeScreenSpotError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={noDefectImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>No Defect</p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </fieldset>

                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-2"><b>  Line Errors</b></p>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenlineerror"
                                                                        value="SLi"
                                                                        checked={screenlineerror === 'SLi'}
                                                                        onChange={handleOptionChangeScreenLineError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={singleLineonDisplayImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Single Line on Display


                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenlineerror"
                                                                        value="SMLi"
                                                                        checked={screenlineerror === 'SMLi'}
                                                                        onChange={handleOptionChangeScreenLineError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={multipleLineonDisplayImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Multi Lines on Display

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenlineerror"
                                                                        value=""
                                                                        checked={screenlineerror === ''}
                                                                        onChange={handleOptionChangeScreenLineError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={noDefectImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>No Defect
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>


                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-2"><b> Screen Physical Condition</b></p>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenphysicalcondition"
                                                                        value="Sbr1"
                                                                        checked={screenphysicalcondition === 'Sbr1'}
                                                                        onChange={handleOptionChangeScreenPhysicalCondition} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={frontGlassCrackedImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Front Glass Broken or Cracked
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenphysicalcondition"
                                                                        value="Sbr2"
                                                                        checked={screenphysicalcondition === 'Sbr2'}
                                                                        onChange={handleOptionChangeScreenPhysicalCondition} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={chippedCrackedImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Chipped Cracked
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenphysicalcondition"
                                                                        value="SHeavy"
                                                                        checked={screenphysicalcondition === 'SHeavy'}
                                                                        onChange={handleOptionChangeScreenPhysicalCondition} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={heavyScratches} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Heavy Scratches

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenphysicalcondition"
                                                                        value="SLight"
                                                                        checked={screenphysicalcondition === 'SLight'}
                                                                        onChange={handleOptionChangeScreenPhysicalCondition} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={OneAndTwoScratches} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>1 or 2 Scratches

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="screenphysicalcondition"
                                                                        value=""
                                                                        checked={screenphysicalcondition === ''}
                                                                        onChange={handleOptionChangeScreenPhysicalCondition} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={noDefectImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>No Defect
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <div className="mt-2 text-center" style={{ display: "flex", justifyContent: "space-between" }}>

                                                        <button className="btn custom-btn backbtn" onClick={prevStep}>
                                                            <i className="bi bi-arrow-left"></i>  Back
                                                        </button>
                                                        <button className="btn custom-btn" onClick={nextStep}
                                                            disabled={(touchScreen == '' || screenspoterror == null || screenlineerror == null || screenphysicalcondition == null)}
                                                        >
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        )}

                                        {step === 3 && (
                                            <div className="form-step">
                                                <h6 className="mb-2">
                                                    Body Errors
                                                </h6>
                                                <div className='features'>
                                                    <p className="mb-2"><b> Scratches</b></p>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="bodyscratches"
                                                                        value="B1"
                                                                        checked={bodyscratches === 'B1'}
                                                                        onChange={handleOptionChangeBodyScratches} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={OneAndTwoScratches} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>1-2 Scratches
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="bodyscratches"
                                                                        value="B2"
                                                                        checked={bodyscratches === 'B2'}
                                                                        onChange={handleOptionChangeBodyScratches} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={MajorScratches} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Major Scratches
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="bodyscratches"
                                                                        value=""
                                                                        checked={bodyscratches === ''}
                                                                        onChange={handleOptionChangeBodyScratches} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={noDefectImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>No Defect
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>


                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-2"><b>  Dents on Body</b></p>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="bodydent"
                                                                        value="D1"
                                                                        checked={bodydent === 'D1'}
                                                                        onChange={handleOptionChangeBodyDent} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={oneAndTwoDentsImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>1-2 Dents


                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="bodydent"
                                                                        value="D2"
                                                                        checked={bodydent === 'D2'}
                                                                        onChange={handleOptionChangeBodyDent} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={HeavyDentsAndCrackedImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Heavy Dents and Cracked

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="bodydent"
                                                                        value=""
                                                                        checked={bodydent === ''}
                                                                        onChange={handleOptionChangeBodyDent} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={noDefectImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>No Defect
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>


                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-2"><b> Panel Errors</b></p>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="panelerror"
                                                                        value="PanCr"
                                                                        checked={panelerror === 'PanCr'}
                                                                        onChange={handleOptionChangePanelError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={crackedBrokenSideORBackPanelImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Cracked - Broken Side or Back Panel


                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="panelerror"
                                                                        value="PanMis"
                                                                        checked={panelerror === 'PanMis'}
                                                                        onChange={handleOptionChangePanelError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={missingSideORBackPanelImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Missing Side or Back Panel

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="panelerror"
                                                                        value=""
                                                                        checked={panelerror === ''}
                                                                        onChange={handleOptionChangePanelError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={noDefectImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>No Defect
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>


                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <p className="mb-2"><b> Bend Errors</b></p>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="benderror"
                                                                        value="PanBent"
                                                                        checked={benderror === 'PanBent'}
                                                                        onChange={handleOptionChangeBendError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={BodyBendORDeformImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Body Bend or Deform
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="benderror"
                                                                        value="PanLoose"
                                                                        checked={benderror === 'PanLoose'}
                                                                        onChange={handleOptionChangeBendError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={looseGapScreenImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Loose Gap in Screen

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="benderror"
                                                                        value=""
                                                                        checked={benderror === ''}
                                                                        onChange={handleOptionChangeBendError} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={noDefectImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>No Defect
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>


                                                        </fieldset>
                                                    </div>
                                                </div>

                                                <div className='features'>
                                                    <div className="mt-2 text-center" style={{ display: "flex", justifyContent: "space-between" }}>

                                                        <button className="btn custom-btn backbtn" type='button' onClick={prevStep}>
                                                            <i className="bi bi-arrow-left"></i>  Back
                                                        </button>
                                                        <button className="btn custom-btn" onClick={nextStep}
                                                            disabled={(bodyscratches == null || bodydent == null || panelerror == null || benderror == null)}>
                                                            Continue
                                                        </button>
                                                    </div>
                                                </div>


                                            </div>
                                        )}

                                        {step === 4 && (
                                            <div className="form-step">
                                                <div className='features'>
                                                    <h6 className="mb-2">
                                                        Functional or Physical Error
                                                    </h6>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="F1"
                                                                        checked={functionalerrorList.F1}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={frontCameraFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Front Camera Faulty

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">

                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="F2"
                                                                        checked={functionalerrorList.F2}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={backCameraFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Back Camera Faulty
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">

                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="Vbutton"
                                                                        checked={functionalerrorList.Vbutton}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={volumeButtonFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Volume Button Faulty


                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">

                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="FTouch"
                                                                        checked={functionalerrorList.FTouch}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={fingerSensorFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Finger Sensor Faulty

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="wifi"
                                                                        checked={functionalerrorList.wifi}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={wifiFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Wifi Faulty
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="Battery"
                                                                        checked={functionalerrorList.Battery}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={batteryFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Battery Faulty

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="Spk"
                                                                        checked={functionalerrorList.Spk}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={speakerFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Speaker Faulty
                                                                        </p>

                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">

                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="PwBut"
                                                                        checked={functionalerrorList.PwBut}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={powerButtonFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Power Button Faulty
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="AudRcv"
                                                                        checked={functionalerrorList.AudRcv}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={audioReceiverFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>
                                                                            Audio Receiver Faulty
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="chgPort"
                                                                        checked={functionalerrorList.chgPort}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={charginFaultyImg} style={{ width: "110px" }} />
                                                                        </span>

                                                                        <p className='option-itemName1'>
                                                                            Charging Faulty

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="FcSnsr"
                                                                        checked={functionalerrorList.FcSnsr}
                                                                        onChange={handleChangeFunctionalError}
                                                                    />
                                                                    <span className="checkbox-tile FunctionalError">
                                                                        <span className="checkbox-icon">
                                                                            <img src={faceSensorFaultyImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>
                                                                            Face Sensor Faulty

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                        <div className='features'>
                                                            <div className="mt-2 text-center" style={{ display: "flex", justifyContent: "space-between" }}>

                                                                <button className="btn custom-btn backbtn" onClick={prevStep}>
                                                                    <i className="bi bi-arrow-left"></i>  Back
                                                                </button>
                                                                <button className="btn custom-btn" onClick={nextStep}>
                                                                    Continue
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {step === 5 && (
                                            <div className="form-step">
                                                <div className='features'>
                                                    <h6 className="mb-2">
                                                        Do you have the following accessories ?

                                                    </h6>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">

                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="Box"
                                                                        checked={accessoriesList.Box}
                                                                        onChange={handleChangeAccessories}
                                                                    />

                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={originalBoxImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Original Box with same IMEI/Serial No
                                                                        </p>
                                                                    </span>

                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="Chr"
                                                                        checked={accessoriesList.Chr}
                                                                        onChange={handleChangeAccessories}
                                                                    />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon ">
                                                                            <img src={originalchargerImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Original Charger</p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        name="EarPhone"
                                                                        checked={accessoriesList.EarPhone}
                                                                        onChange={handleChangeAccessories}
                                                                    />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={originalEarphonesImg} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Orignal Earphones</p>
                                                                    </span>

                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                        <div className='features'>
                                                            <div className="mt-2 text-center" style={{ display: "flex", justifyContent: "space-between" }}>

                                                                <button className="btn custom-btn backbtn" onClick={prevStep}>
                                                                    <i className="bi bi-arrow-left"></i>  Back
                                                                </button>
                                                                {phoneUnderWarranty === 'yes' ? (
                                                                    <button className="btn custom-btn" onClick={nextStep}>
                                                                        Continue
                                                                    </button>
                                                                ) : (
                                                                    <button className="btn custom-btn" >
                                                                        Confirm
                                                                    </button>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                        {step === 6 && (
                                            <div className="form-step">
                                                <div className='features'>
                                                    <h6 className="mb-2">
                                                        Warranty Info
                                                    </h6>
                                                    <div>
                                                        <fieldset className="row">
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="warranty"
                                                                        value="warranty03"
                                                                        checked={warranty === 'warranty03'}
                                                                        onChange={handleOptionChangeWarranty} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={warranty03Img} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>0-3 Months
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="warranty"
                                                                        value="warranty36"
                                                                        checked={warranty === 'warranty36'}
                                                                        onChange={handleOptionChangeWarranty} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={warranty36Img} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>3-6 Months

                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>

                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="warranty"
                                                                        value="warranty611"
                                                                        checked={warranty === 'warranty611'}
                                                                        onChange={handleOptionChangeWarranty} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={warranty611Img} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>6-11 Months
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>

                                                            <div className="col-xl-2 col-lg-4 col-md-4 col-6 mb-3">
                                                                <label className="checkbox-wrapper">
                                                                    <input type="radio" className="checkbox-input"
                                                                        name="warranty"
                                                                        value="warrantyAbove11"
                                                                        checked={warranty === 'warrantyAbove11'}
                                                                        onChange={handleOptionChangeWarranty} />
                                                                    <span className="checkbox-tile checkboxScreen">
                                                                        <span className="checkbox-icon">
                                                                            <img src={warrantyAbove11Img} style={{ width: "110px" }} />
                                                                        </span>
                                                                        <p className='option-itemName1'>Above 11 Months
                                                                        </p>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                </div>
                                                <div className='features'>
                                                    <div className="mt-2 text-center" style={{ display: "flex", justifyContent: "space-between" }}>

                                                        <button className="btn custom-btn backbtn" onClick={prevStep}>
                                                            <i className="bi bi-arrow-left"></i>  Back
                                                        </button>
                                                        <button type="submit" className="btn custom-btn" disabled={warranty == null} >
                                                            Confirm
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>

                </div>

            </section>

        </div>
    );
}

export default Requote;