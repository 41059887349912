import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { generatesellnoworder, getExactValue, getcustomeraddress } from '../../utils/api';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import './sellnow.css';
import AddressModal from '../../components/address-modal/addressModal';
import DeleteModal from '../../components/delete-modal/deleteModal';
import Loader from '../../components/loader/loader';
const Sellnow = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userData')));
    const [isOpen, setIsOpen] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const [selectedaddress, setSelectedAddress] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedModel, setSelectedModel] = useState(JSON.parse(localStorage.getItem('selectedModel')) || null);
    const [exactValue, setExactValue] = useState(null);
    const [finalValue, setFinalValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedAddressId, setSelectedAddressId] = useState(null);
    const [selectedPickupDate, setSelectedPickupDate] = useState(null);
    const [selectedPickupTime, setSelectedPickupTime] = useState(null);
    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const openEditModal = (obj) => {
        setSelectedAddress(obj);
        setIsModalOpen(true);
    };

    const openDeleteModal = (obj) => {
        setSelectedAddress(obj);
        setIsDeleteModalOpen(true);
    };

    const closeModal = () => {
        setSelectedAddress(null);
        fetchData();
        setIsModalOpen(false);
    };

    const closeDeleteModal = () => {
        fetchData();
        setSelectedAddressId(null);
        setIsDeleteModalOpen(false);
    };


    let currentDate = new Date();
    let datesList = [];
    let timeSlotList = ["08:00 AM - 10:00 AM", "10:00 AM - 12:00 PM", "12:00 PM - 02:00 PM", "02:00 PM - 04:00 PM", "04:00 PM - 06:00 PM", "06:00 PM - 08:00 PM"];
    function padZero(num) {
        return (num < 10 ? '0' : '') + num;
    }
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    for (let i = 1; i <= 4; i++) {
        currentDate.setDate(currentDate.getDate() + 1);
        let day = padZero(currentDate.getDate());
        let month = currentDate.toLocaleString('default', { month: 'long' });
        let date = currentDate.toISOString().split('T')[0]; // Get date in YYYY-MM-DD format
        let dayName = daysOfWeek[currentDate.getDay()]; // Get day name

        let dateObject = {
            day: day,
            month: month,
            date: date,
            dayName: dayName
        };
        datesList.push(dateObject);
    }

    const ConfirmSellNow = async () => {
        setLoading(true);
        try {
            let obj = {
                ic_slug: selectedModel.ic_slug,
                model_name: selectedModel.model_name,
                variant: selectedModel.variant,
                exactValue: exactValue,
                device_info: JSON.stringify(selectedModel),
                customer_id: userInfo?.customer_id,
                address_id: selectedAddressId,
                pickup_date: selectedPickupDate,
                pickup_time: selectedPickupTime
            }
            console.log(obj);
            const response = await generatesellnoworder(obj);
            if (response?.success) {
                navigate('/response/' + response?.RefNo);
            } else {
                toast.error(response?.message);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching sell mobile data:', error);
            setLoading(false);
        }
    };

    const handleInputAddressChange = (event) => {
        setSelectedAddressId(event.target.value);
    };


    const handleInputPickupDateChange = (event) => {
        setSelectedPickupDate(event.target.value);
    };

    const handleInputPickupTimeChange = (event) => {
        setSelectedPickupTime(event.target.value);
    };

    const fetchData = async () => {
        setLoading(true);
        const response = await getcustomeraddress(userInfo?.customer_id);
        if (response?.success) {
            setAddresses(response?.addresses);
            if (response?.addresses.length == 1) {
                setSelectedAddressId(response?.addresses[0]?.id);
            }
            else {
                setSelectedAddressId(response?.addresses?.filter(value => value?.is_default == true)[0]?.id);
            }
        } else {
            toast.error(response?.message);
        }

        try {
            const response = await getExactValue(selectedModel);
            const formattedAmount = new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR'
            }).format(response?.finalValue);
            setFinalValue(formattedAmount);
            setExactValue(response?.finalValue);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching sell mobile data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {loading && <Loader />}
            {isModalOpen && <AddressModal isOpen={isModalOpen} onClose={closeModal} selectedaddress={selectedaddress} />}
            {isDeleteModalOpen && <DeleteModal isDeleteOpen={isDeleteModalOpen} OnDeleteClose={closeDeleteModal} selectedaddress={selectedaddress} />}
            <section className="about-section section-padding" id="section_2">
                <div className="container ">
                    <p className='sell-result-Desktop'><b>You're almost done
                    </b></p>
                    <p className='sell-result-Mobile mt-5'><b>You're almost done
                    </b></p>
                    <div className='row justify-content-center'>
                        <div className="col-md-8">
                            <div className="custom-block" style={{ marginBottom: "10px", padding: "12px" }} >
                                <div className='row'>
                                    <div className="col-md-12 mt-1" >
                                        <h6>Address</h6>
                                        <hr />
                                    </div>
                                    <div className='col-md-12 text-left'>
                                        <div className=""><button className="btn custom-btn1 " onClick={openModal}>+ Add New Address</button></div>
                                    </div>
                                    {addresses.length > 0 && addresses.map((add, index) => (
                                        <div className='row mt-3' key={add?.id}>
                                            <div className="col-md-10">
                                                <input
                                                    type="radio"
                                                    className='me-2'
                                                    id={add?.id}
                                                    name="radio-group"
                                                    value={add?.id}
                                                    onChange={handleInputAddressChange}
                                                    checked={selectedAddressId == add?.id} // Set checked if address length is one and it's the first address
                                                />
                                                <label htmlFor={add?.id} className='pointer'>
                                                    {add?.address_no && <p className='mb-0'>{add?.address_no}
                                                        {add?.landmark && <span>, {add?.landmark}</span>}</p>}
                                                    <p>{add?.locality && <span>{add?.locality},</span>} {add?.city}, {add?.state}, {add?.pincode}</p>
                                                </label>
                                            </div>
                                            <div className="col-md-2 text-right">
                                                <div className="">
                                                    <button className='button-like-link me-3' onClick={() => { openEditModal(add); }}>Edit</button>
                                                    <button className='button-like-link me-3 deletelink' onClick={() => { openDeleteModal(add); }}>Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {addresses.length === 0 && (
                                        <p className='text-center'>No Saved Addresses</p>
                                    )}

                                </div>
                            </div>
                            <div className="custom-block" style={{ marginBottom: "10px", padding: "12px" }} >
                                <div className='row'>
                                    <div className="col-md-12 mt-1" >
                                        <h6>Pickup Slot
                                        </h6>
                                        <hr />
                                    </div>

                                    <div className='row mt-3'>
                                        <div className="col-md-12 " >
                                            <p className='ms-3'><b>Please select your preferable pickup date</b></p>
                                            <div className="row">
                                                {datesList.map((d, index) => (
                                                    <label className="custom-radio col-xl-2 col-md-4 col-6" key={index}>
                                                        <input type="radio" name="dayslots" value={d.date} onChange={handleInputPickupDateChange} />
                                                        <span className="radio-btn">
                                                            <div className="hobbies-icon">
                                                                <p>{d.month}</p>
                                                                <h4 className="">{d.day}</h4>
                                                                <p>{d.dayName}</p>
                                                            </div>
                                                        </span>
                                                    </label>
                                                ))}

                                            </div>


                                        </div>
                                        <div className="col-md-12 " >
                                            <p className='ms-3' style={{ letterSpacing: '0.72px' }}><b><span style={{ color: "red" }}>Note: </span> We will contact  you as soon as possible</b></p>

                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className="col-md-12 " >
                                            <p className='ms-3'><b>Your availability on that day</b></p>
                                            <div className="row">
                                                {timeSlotList.map((t, index) => (
                                                    <label className="custom-radio col-xl-4 col-md-6 col-6" key={index}>
                                                        <input type="radio" name="timeslots" value={t} onChange={handleInputPickupTimeChange} />
                                                        <span className="radio-btn">
                                                            <div className="hobbies-icon">
                                                                <p className='mb-2'> {t}</p>
                                                            </div>
                                                        </span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="custom-block1" style={{ boxShadow: "0 0rem 1rem rgba(0, 0, 0, .175)" }}>
                                <div className='row'>
                                    <div className="col-md-4" >
                                        <div className="custom-block-icon-wrap" style={{ marginTop: "10%" }}>
                                            <div className="custom-block-image-wrap text-center">
                                                <img src={selectedModel?.item_image} alt="img" style={{ width: "auto", height: "100px", margin: "0 auto" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-block-info col-md-8 mt-4">
                                        <h6 className=' mb-0'>{selectedModel?.model_name}</h6>
                                        <h6 className=' mb-0'>{selectedModel?.variant_name}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="custom-block1 mt-4" >
                                <div className="text_content">
                                    <h6>Price Summary</h6>
                                </div>
                                <div className="plan pt-4 ">
                                    <div className='orderPrice'>
                                        <div className='pt-0'>
                                            <p className='mb-2'>Base Price</p>
                                        </div>
                                        <p className='pt-0 mb-2'>{finalValue}</p>
                                    </div>

                                    <div className='orderPrice'>
                                        <div className='pt-0 mb-2'>
                                            <p className='mb-2'> Pickup Charges</p>
                                        </div>
                                        <p className='pt-0 mb-2'>Free</p>
                                    </div>

                                    <div className='orderPrice totalAmountSumry'>
                                        <div className='pt-2 mb-2'>
                                            <p className=''> <b>Total Amount</b></p>
                                        </div>
                                        <p className='pt-2 mb-2'> {finalValue}</p>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="payment-options mt-2">
                                        <button type="button" className="btn custom-btn1" disabled={!selectedAddressId || !selectedPickupDate || !selectedPickupTime} onClick={() => { ConfirmSellNow(); }} style={{ width: "100%" }}>Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-section section-padding">
                <div className="container">
                </div>
            </section>
        </>
    );
}

export default Sellnow;