import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom"
import Slider from '../../components/slider/Slider';
import service1 from '../../assets/images/services/1.png';
import service2 from '../../assets/images/services/2.png';
import service3 from '../../assets/images/services/3.png';
import ResearchImg from '../../assets/images/icon/research.png';
import RequestImg from '../../assets/images/icon/request-for-proposal.png';
import PickUpImg from '../../assets/images/icon/pickup.png';
import PhoneImg from '../../assets/images/icon/phone.png';
import WalletImg from '../../assets/images/icon/wallet.png';
import VerifyImg from '../../assets/images/icon/verification.png';
import RecommendationImg from '../../assets/images/icon/recommendation.png';
import DiscountImg from '../../assets/images/icon/discount.png';
import CardPaymentImg from '../../assets/images/icon/card-payment.png';
import sellMobileIcon from '../../assets/images/icon/sell-mobile.png';
import buyMobileIcon from '../../assets/images/icon/buy-mobile.png';
import repairMobileIcon from '../../assets/images/icon/repair-mobile.png';


import '../../index.css';

const Home = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearchQuery(value);
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission when Enter is pressed
            navigate(`/search-result/${searchQuery}`)
        }
    };
    const handleSubmit = (event) => {
        event.preventDefault(); // This will prevent the default form submission behavior
        navigate(`/search-result/${searchQuery}`)
    };

    return (
        <>
            <div className='container'>
                <Slider />
            </div>
            <div className="container mt-5 mb-5">

                <div className="col-lg-12 col-12  actionGrid text-center" style={{ padding: "10px" }}>
                    <div className="card-header text-center">
                        <h6 className="m-0 p-0">What would you like to do?</h6>
                    </div>
                    <div className="col-lg-12 col-12">
                        <div className='row'>
                            <div className="col-lg-4 col-md-6 col-6 mb-4 mb-lg-0 pt-3 " style={{ borderRight: "1px solid rgba(24, 28, 33, 0.06)" }}>
                                <Link to="/sell-mobile" className="card1 cardbgChange" title="Sell Mobile Service">

                                    <div className="custom-block-image-wrap">
                                        <img src={sellMobileIcon} className="img-fluid m-ScreenImg-icon" alt="Sell Mobile" />
                                        <div className="medium pt-2 pb-3">Sell Mobile</div>

                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-6 mb-4 mb-lg-0 pt-3" style={{ borderRight: "1px solid rgba(24, 28, 33, 0.06)" }}>
                                <Link to="/buy-mobile" className="card1 cardbgChange" title="Buy Mobile Service">
                                    <div className="custom-block-image-wrap">
                                        <img src={buyMobileIcon} className="img-fluid m-ScreenImg-icon" alt="Buy Mobile" />
                                        <div className="medium  pt-2 pb-3">Buy Mobile</div>

                                    </div>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-md-6 col-6 mb-4 mb-lg-0 pt-3">
                                <Link to="/buy-mobile" className="card1 cardbgChange" title="Repair Mobile Service">
                                    <div className="custom-block-image-wrap">
                                        <img src={repairMobileIcon} className="img-fluid m-ScreenImg-icon" alt="Repair Mobile" />
                                        <div className="medium  pt-2 pb-3">Repair Mobile</div>

                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>




            {/* <section className="trending-podcast-section section-padding">
                <div className="container">
                    <div className="row services-Desktop" >

                        <div className="col-lg-12 col-12">
                            <div className="section-title-wrap mb-4">
                                <h4 className="section-title">Our Services</h4>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-full p0">
                                    <div className="custom-block-image-wrap">
                                        <NavLink to="/" >
                                            <img src={service1} className="custom-block-image img-fluid"
                                                alt="" />
                                        </NavLink>
                                    </div>


                                </div>
                            </div>

                            <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                                <div className="custom-block custom-block-full p0">
                                    <div className="custom-block-image-wrap">
                                        <NavLink to="/" >
                                            <img src={service2} className="custom-block-image img-fluid"
                                                alt="" />
                                        </NavLink>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-4 col-12">
                                <div className="custom-block custom-block-full p0">
                                    <div className="custom-block-image-wrap">
                                        <NavLink to="/" >
                                            <img src={service3} className="custom-block-image img-fluid"
                                                alt="" />
                                        </NavLink>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="services-Mobile" >

                        <div className="col-lg-12 col-12">
                            <div className="section-title-wrap mb-4">
                                <h4 className="section-title">Our Services</h4>
                            </div>
                        </div>
                        <div className="services-scroll">
                            <div className="mb-0 mb-lg-0 service-size">
                                <div className="custom-block custom-block-full p0">
                                    <div className="custom-block-image-wrap">
                                        <NavLink to="/" >
                                            <img src={service1} className="custom-block-image img-fluid"
                                                alt="" />
                                        </NavLink>
                                    </div>


                                </div>
                            </div>

                            <div className="mb-0 mb-lg-0 service-size">

                                <div className="custom-block custom-block-full p0">
                                    <div className="custom-block-image-wrap">
                                        <NavLink to="/" >
                                            <img src={service2} className="custom-block-image img-fluid"
                                                alt="" />
                                        </NavLink>
                                    </div>

                                </div>
                            </div>
                            <div className="mb-0 mb-lg-0 service-size">

                                <div className="custom-block custom-block-full p0">
                                    <div className="custom-block-image-wrap">
                                        <NavLink to="/" >
                                            <img src={service3} className="custom-block-image img-fluid"
                                                alt="" />
                                        </NavLink>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section> */}
            <section className="trending-podcast-section section-padding bgImage">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                            <div className="section-title-wrap mb-4">
                                <h4 className="section-title">How it Works</h4>
                            </div>
                        </div>


                        <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                            <div className="custom-block custom-block-full p10 hiw-custom-block" style={{ borderRadius: "9px", backgroundColor: "fff" }}>
                                <div className='row'>
                                    <div className="custom-block-image-wrap textcenter col-lg-3">
                                        <img src={ResearchImg} className="img-fluid hitwIcon" alt="" />
                                    </div>
                                    <div className="custom-block-info card-padding col-lg-9">
                                        <h4 className="mb-0">
                                            Search Your Device

                                        </h4>
                                        <p className="mb-0">Find the item that you wish to sell by visiting out website or App</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                            <div className="custom-block custom-block-full p10 hiw-custom-block" style={{ borderRadius: "9px", backgroundColor: "fcfbfd" }}>
                                <div className='row'>
                                    <div className="custom-block-image-wrap textcenter col-lg-3">
                                        <img src={RequestImg} className="img-fluid hitwIcon" alt="" />
                                    </div>
                                    <div className="custom-block-info card-padding col-lg-9">
                                        <h4 className="mb-0">
                                            Get Instant Quote
                                        </h4>
                                        <p className="mb-0">Answer few questions on your device condition and get instant quote</p>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                            <div className="custom-block custom-block-full p10 hiw-custom-block" style={{ borderRadius: "9px", backgroundColor: "fcfbfd" }}>
                                <div className='row'>
                                    <div className="custom-block-image-wrap textcenter col-lg-3">
                                        <img src={PickUpImg} className="img-fluid hitwIcon" alt="" />
                                    </div>
                                    <div className="custom-block-info card-padding col-lg-9">
                                        <h4 className="mb-0">
                                            Place Pick Up Order
                                        </h4>
                                        <p className="mb-0">
                                            Provide your details and choose preferred pick up date time slot to place an order with us
                                        </p>                            </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4 mb-lg-0">
                            <div className="custom-block custom-block-full p10 hiw-custom-block" style={{ borderRadius: "9px", backgroundColor: "fcfbfd" }}>
                                <div className='row'>
                                    <div className="custom-block-image-wrap textcenter col-lg-3">
                                        <img src={PhoneImg} className="img-fluid hitwIcon" alt="" />
                                    </div>
                                    <div className="custom-block-info card-padding col-lg-9">
                                        <h4 className="mb-0">
                                            Get Pickup Call

                                        </h4>
                                        <p className="mb-0">
                                            Pick up executive will call you for door step pick up of your device.


                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4 mb-lg-0">
                            <div className="custom-block custom-block-full p10 hiw-custom-block" style={{ borderRadius: "9px", backgroundColor: "fcfbfd" }}>
                                <div className='row'>
                                    <div className="custom-block-image-wrap textcenter col-lg-3">
                                        <img src={VerifyImg} className="img-fluid hitwIcon" alt="" />
                                    </div>
                                    <div className="custom-block-info card-padding col-lg-9">
                                        <h4 className="mb-0">
                                            Verification
                                        </h4>
                                        <p className="mb-0">
                                            Get diagnosis of device and verification of your identity.


                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4 mb-lg-0">
                            <div className="custom-block custom-block-full p10 hiw-custom-block" style={{ borderRadius: "9px", backgroundColor: "fcfbfd" }}>
                                <div className='row'>
                                    <div className="custom-block-image-wrap textcenter col-lg-3">
                                        <img src={WalletImg} className="img-fluid hitwIcon" alt="" />
                                    </div>
                                    <div className="custom-block-info card-padding col-lg-9">
                                        <h4 className="mb-0">
                                            Get Paid

                                        </h4>
                                        <p className="mb-0">
                                            Get paid as soon as our executive verified your device? Its instant payment all the way!

                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trending-podcast-section section-padding">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 col-12">
                            <div className="section-title-wrap mb-4">
                                <h4 className="section-title">Why us</h4>
                            </div>
                        </div>

                        <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                            <div className="custom-block custom-block-full p30 wu-custom-block">
                                <div className="custom-block-image-wrap textcenter" style={{ padding: "0px" }}>

                                    <img src={RecommendationImg} className="img-fluid" alt="" />

                                </div>

                                <div className="custom-block-info" style={{ height: "252px" }}>
                                    <h4 className="mb-2">

                                        Simple and Hassle-free

                                    </h4>



                                    <p className="mb-14">Seal the deal in just few simple steps! Get a price offer, fix a convenient location for pickup and finally, receive payment before the phone leaves your hands.</p>

                                    <p className="mb-10"></p>
                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                            <div className="custom-block custom-block-full p30 wu-custom-block">
                                <div className="custom-block-image-wrap textcenter">

                                    <img src={DiscountImg} className="img-fluid" alt="" />

                                </div>

                                <div className="custom-block-info">
                                    <h4 className="mb-2">

                                        Best Price Estimate - Instantly!



                                    </h4>



                                    <p className="mb-0">Based on phone brand, specs, age, condition and market forces this intelligent dynamic tool will delight you with a price that goes far beyond your expectations. No pesky phone calls, no haggling or negotiations.</p>


                                </div>


                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mb-4 mb-lg-0">
                            <div className="custom-block custom-block-full p30 wu-custom-block">
                                <div className="custom-block-image-wrap textcenter">

                                    <img src={CardPaymentImg} className="img-fluid" alt="" />

                                </div>

                                <div className="custom-block-info" style={{ height: "252px" }}>
                                    <h4 className="mb-2">

                                        Instant Money & Secure Transaction



                                    </h4>



                                    <p className="mb-0">
                                        Our money transfers are digital. Get your money via Bank Account or Paytm, instantly, before your phone leaves your hands. We keep all your financial details confidential and never share any data
                                    </p>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="cta-section">
                <h2>Join Us as a Partner</h2>
                <p>Partner with us to grow your business. Fill out the form and become a trusted partner!</p>
                <Link to="/partner-form" className="btn-cta mt-3">Become a Partner</Link>
            </div>

        </>

    )
}

export default Home;