import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import TopHeader from "../top-header/TopHeader"
import Logo from '../../assets/images/brand-logo.png';
import LoginModal from '../login-modal/loginModal';
import CheckoutModal from '../checkout-modal/checkoutModal';
import { useUser } from "../../UserContext";
import './header.css'
function Header() {
    const navigate = useNavigate();
    const { user, logoutUser } = useUser();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);
    const location = useLocation();

    const [isLogin, setIsLogin] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    // Toggle the search bar visibility
    const toggleSearchBar = () => {
        setIsSearchVisible(!isSearchVisible);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Add your search handling logic here
        const searchQuery = event.target.search.value;
        console.log("Search for:", searchQuery);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };
    const CheckoutopenModal = () => {
        setIsCheckoutModalOpen(true);
    };
    const closeCheckoutModal = () => {
        setIsCheckoutModalOpen(false);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const closeDropdown = () => {
        setIsOpen(false);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeDropdown();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen]);


    const handleMenuClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            const element = document.querySelector('.navbar-collapse.show');
            if (element) {
                document.getElementById('btn_menu').click();
            }
        }
    };

    const toggleMenuDropdown = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('click', handleMenuClickOutside);
        } else {
            document.removeEventListener('click', handleMenuClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleMenuClickOutside);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        closeDropdown();
    }, [location]);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearchQuery(value);
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission when Enter is pressed
            navigate(`/search-result/${searchQuery}`)
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // This will prevent the default form submission behavior
        setIsSearchVisible(false);
        navigate(`/search-result/${searchQuery}`)
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission when Enter is pressed
            navigate(`/search-result/${searchQuery}`)
        }
    };

    const logout = () => {
        navigate('/');
        logoutUser();
    };

    return (
        <>
            <TopHeader className="top-header-Desktop" />
            {isModalOpen && <LoginModal isOpen={isModalOpen} onClose={closeModal} />}
            {isCheckoutModalOpen && <CheckoutModal isOpen={isCheckoutModalOpen} onClose={closeCheckoutModal} />}


            <nav className="navbar navbar-expand-lg search-Desktop" >
                <div className="container">
                    <NavLink className="navbar-brand me-lg-2 me-0" to="/">
                        <img src={Logo} className="logo-image  img-fluid" alt="" style={{}} />
                    </NavLink>

                    <form className="custom-form search-form flex-fill me-1 " onSubmit={handleSubmit}>
                        <div className="input-group input-group-lg">
                            <input name="search" type="search" className="form-control" onChange={handleInputChange} id="search" placeholder="Search For..."
                                aria-label="Search" />

                            <button type="submit" className="form-control top-search-btn">
                                <i className="bi-search"></i>
                            </button>
                        </div>
                    </form>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-lg-auto">

                            <li className="nav-item">
                                <NavLink to="/sell-mobile" className="nav-link">Sell Mobile</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/buy-mobile" className="nav-link"> Buy Mobile</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/repair-mobile" className="nav-link">Repair Mobile</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink to="/partner-form" className="nav-link">Partner</NavLink>
                            </li> */}
                            <form onSubmit={handleSubmit} className="custom-form search-form flex-fill me-2 search-Mobile" role="search" style={{ margin: "15px 0px", maxWidth: "100%" }}>
                                <div className="input-group input-group-lg">
                                    <input name="search" type="search" className="form-control" id="search" onChange={handleInputChange} placeholder="Search For..."
                                        aria-label="Search" />

                                    <button type="submit" className="form-control" id="submit">
                                        <i className="bi-search"></i>
                                    </button>
                                </div>
                            </form>
                            {user ? (


                                <div>
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle" type="button" id="userDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src="/images/profile/user.png" className="logo-image  img-fluid" alt="" style={{ width: "36px" }} />
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="userDropdown">
                                            <NavLink onClick={closeDropdown} className="dropdown-item colorBlack" to="/editprofile">{user.name}</NavLink>
                                            <div className="dropdown-divider"></div>
                                            <NavLink onClick={closeDropdown} className="dropdown-item colorBlack" to="/sales-orders"><i className="bi bi-phone"></i> Sales</NavLink>
                                            <NavLink onClick={closeDropdown} className="dropdown-item colorBlack" to="/purchase-orders"><i className="bi bi-phone-fill"></i> Purchases</NavLink>
                                            <NavLink onClick={closeDropdown} className="dropdown-item colorBlack" to="/editprofile"><i className="bi bi-person-circle"></i> Profile</NavLink>
                                            <div className="dropdown-divider"></div>

                                            <button className="dropdown-item colorBlack" onClick={logout}><i className="bi bi-box-arrow-right"></i> Logout</button>

                                        </div>
                                    </div>
                                </div>

                            ) : (
                                <>
                                    <div className="ms-4">
                                        <button className="btn custom-btn custom-border-btn smoothscroll" onClick={openModal}> Login</button>
                                    </div>

                                </>

                            )}


                        </ul>


                    </div>
                </div>
            </nav>
            <nav className="navbar navbar-expand-lg search-Mobile">
                <div className="container">
                    <NavLink className="navbar-brand me-auto" to="/">
                        <img src="/images/brand-logo.png" className="logo-image img-fluid" alt="" />
                    </NavLink>

                    {user ? (
                        <>
                            <div className="user-icon-dropdown me-2" ref={dropdownRef}>
                                <div className="user-icon" onClick={toggleDropdown}>
                                    <img src="/images/profile/user.png" className="logo-image img-fluid" alt="" style={{ width: "36px" }} />
                                </div>
                                {isOpen && (
                                    <div className="dropdown-menu mobile-dropdown-menu">
                                        <NavLink className="dropdown-item colorBlack" to="/editprofile">{user.name}</NavLink>
                                        <div className="dropdown-divider"></div>
                                        <NavLink className="dropdown-item colorBlack" to="/sales-orders">
                                            <i className="bi bi-phone"></i> Sales
                                        </NavLink>
                                        <NavLink className="dropdown-item colorBlack" to="/purchase-orders">
                                            <i className="bi bi-phone-fill"></i> Purchases
                                        </NavLink>
                                        <NavLink className="dropdown-item colorBlack" to="/editprofile">
                                            <i className="bi bi-person-circle"></i> Profile
                                        </NavLink>
                                        <div className="dropdown-divider"></div>
                                        <button className="dropdown-item colorBlack" onClick={logout}>
                                            <i className="bi bi-box-arrow-right"></i> Logout
                                        </button>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="ms-lg-auto ms-0 me-2">
                            <button className="btn custom-btn custom-border-btn smoothscroll" onClick={openModal}>
                                Login
                            </button>
                        </div>
                    )}

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" id="btn_menu" onClick={toggleMenuDropdown}
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse " id="navbarNav" ref={menuRef}>
                        <ul className="navbar-nav  m-lg-auto">
                            <li className="nav-item">
                                <NavLink to="/sell-mobile" className="nav-link">Sell Mobile</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/buy-mobile" className="nav-link"> Buy Mobile</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/repair-mobile" className="nav-link">Repair Mobile</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <NavLink to="/partner-form" className="nav-link">Partner</NavLink>
                            </li> */}
                            <li>
                                <form onSubmit={handleSubmit} className="custom-form search-form flex-fill me-2 search-Mobile" role="search" style={{ margin: "15px 0px", maxWidth: "100%" }}>
                                    <div className="input-group input-group-lg">
                                        <input name="search" type="search" className="form-control" id="search" onChange={handleInputChange} placeholder="Search For..." aria-label="Search" />
                                        <button type="submit" className="form-control" id="submit">
                                            <i className="bi-search"></i>
                                        </button>
                                    </div>
                                </form>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>




        </>
    )
}

export default Header