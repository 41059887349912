import React, { useState } from 'react';
import './devicereport.css';

function DeviceReport({ selectedModel }) {

    // Hold rendering if selectedModel is not yet loaded
    if (!selectedModel) {
        return <p>Loading device report...</p>;
    }

    function toucherr(s) {
        if (s === 'yes') return 'Working';
        else if (s === 'no') return 'Not Working';
        return 'Working';
    }

    function screenspoterror(s) {
        if (s === 'Sp1') return 'White / Black Spots';
        else if (s === 'Sp2') return 'Different Color Spots';
        else if (s === 'Sp3') return 'Yellow Spots';
        return 'No Defect';
    }

    function screenlineerror(s) {
        if (s === 'SLi') return 'Single Line on Display';
        else if (s === 'SMLi') return 'Multi Lines on Display';
        return 'No Defect';
    }

    function screenphysicalcondition(s) {
        if (s === 'Sbr1') return 'Front Glass Broken or Cracked';
        else if (s === 'Sbr2') return 'Chipped Cracked';
        else if (s === 'SHeavy') return 'Heavy Scratches';
        else if (s === 'SLight') return '1 or 2 Scratches';
        return 'No Defect';
    }

    function bodyscratches(s) {
        if (s === 'B1') return '1-2 Scratches';
        else if (s === 'B2') return 'Major Scratches';
        return 'No Defect';
    }

    function bodydent(s) {
        if (s === 'D1') return '1-2 Dents';
        else if (s === 'D2') return 'Heavy Dents and Cracked';
        return 'No Defect';
    }

    function panelerror(s) {
        if (s === 'PanCr') return 'Cracked - Broken Side or Back Panel';
        else if (s === 'PanMis') return 'Missing Side or Back Panel';
        return 'No Defect';
    }

    function benderror(s) {
        if (s === 'PanBent') return 'Body Bend or Deform';
        else if (s === 'PanLoose') return 'Loose Gap in Screen';
        return 'No Defect';
    }

    function warranty(s) {
        if (s === 'warranty03') return '0-3 Months';
        else if (s === 'warranty36') return '3-6 Months';
        else if (s === 'warranty611') return '6-11 Months';
        else if (s === 'warrantyAbove11') return 'Above 11 Months';
        return 'No Warranty';
    }

    function functionalerror(arr, value) {
        return arr?.includes(value) ? 'Not Working' : 'Working';
    }

    function accessories(arr, value) {
        return arr?.includes(value) ? 'Available' : 'Not Available';
    }

    return (
        <>
            <p><b>SCREEN CONDITION</b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Screen Touch Calibration</th>
                        <td>{toucherr(selectedModel?.toucherr)}</td>
                    </tr>
                    <tr>
                        <th>Screen Display Spot</th>
                        <td>{screenspoterror(selectedModel?.screenspoterror)}</td>
                    </tr>
                    <tr>
                        <th>Screen Display lines</th>
                        <td>{screenlineerror(selectedModel?.screenlineerror)}</td>
                    </tr>
                    <tr>
                        <th>Screen Physical Condition</th>
                        <td>{screenphysicalcondition(selectedModel?.screenphysicalcondition)}</td>
                    </tr>
                </tbody>
            </table>
            <p><b>DEVICE OVERALL CONDITION</b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>SIM (Network)</th>
                        <td>{selectedModel?.callingerr == 'yes' ? 'Working' : 'Not Working'}</td>
                    </tr>
                    <tr>
                        <th>Body Scratches</th>
                        <td>{bodyscratches(selectedModel?.bodyscratches)}</td>
                    </tr>
                    <tr>
                        <th>Body Dents</th>
                        <td>{bodydent(selectedModel?.bodydent)}</td>
                    </tr>
                    <tr>
                        <th>Panel Condition</th>
                        <td>{panelerror(selectedModel?.panelerror)}</td>
                    </tr>
                    <tr>
                        <th>Physical Bends</th>
                        <td>{benderror(selectedModel?.benderror)}</td>
                    </tr>
                </tbody>
            </table>

            <p><b>MOBILE AGE</b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Mobile Period	</th>
                        <td>{warranty(selectedModel?.warranty)}</td>
                    </tr>
                    <tr>
                        <th> Phone Waranty	</th>
                        <td>{selectedModel?.warranty == '' ? 'No Warranty' : 'Warranty'}</td>
                    </tr>
                </tbody>
            </table>

            <p><b>FUNCTIONAL PROBLEMS </b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Front Camera</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'F1')}</td>
                    </tr>
                    <tr>
                        <th>Back Camera</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'F2')}</td>
                    </tr>
                    <tr>
                        <th>WiFi</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'wifi')}</td>
                    </tr>
                    <tr>
                        <th>Volume Button	</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'Vbutton')}</td>
                    </tr>
                    <tr>
                        <th>Battery	</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'Battery')}</td>
                    </tr>
                    <tr>
                        <th>Speaker	</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'Spk')}</td>
                    </tr>
                    <tr>
                        <th>Power Button</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'PwBut')}</td>
                    </tr>
                    <tr>
                        <th>Audio Receiver</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'AudRcv')}</td>
                    </tr>
                    <tr>
                        <th>Charging port</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'chgPort')}</td>
                    </tr>
                    <tr>
                        <th>Finger touch sensor</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'FTouch')}</td>
                    </tr>
                    <tr>
                        <th>Face Sensor</th>
                        <td>{functionalerror(selectedModel?.functionalerror, 'FcSnsr')}</td>
                    </tr>
                </tbody>
            </table>
            <p><b> ACCESSORIES </b> </p>
            <table className="table">
                <tbody>
                    <tr>
                        <th>Ear Phones	</th>
                        <td>{accessories(selectedModel?.accessories, 'EarPhone')}</td>
                    </tr>
                    <tr>
                        <th>Valid Bill</th>
                        <td>{selectedModel?.warranty == '' ? 'Not Available' : 'Available'}</td>
                    </tr>
                    <tr>
                        <th>Box</th>
                        <td>{accessories(selectedModel?.accessories, 'Box')}</td>
                    </tr>
                    <tr>
                        <th>Charger</th>
                        <td>{accessories(selectedModel?.accessories, 'Chr')}</td>
                    </tr>
                </tbody>
            </table>

        </>
    )
}

export default DeviceReport