import React, { useEffect, useState } from 'react';
import './MetroCitiesModal.css'; // Import your custom CSS file
import { getcities } from '../../utils/api';


const MetroCitiesModal = ({ showModal, onClose, onSelectCity }) => {

    const [cityData, setCityData] = useState([]);

    const fetchCity = async () => {
        try {
            const response = await getcities();
            setCityData(response?.data);
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchCity();
    }, []);


    if (!showModal) return null;
    const handleCityClick = (city) => {
        onSelectCity(city);
        onClose();
    };
    return (
        <div className="modal-overlayM">
            <div className="modal-contentM">
                <button className="close-btn" onClick={onClose}>×</button>
                <h4 className='text-start'>Select your City</h4>
                <hr />
                <div className="city-list">
                    {cityData?.map((city, index) => (
                        <div
                            key={index}
                            className="city-item"
                            onClick={() => handleCityClick(city.city_name)}
                        >
                            <p>{city.city_name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MetroCitiesModal;
