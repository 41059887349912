import { useEffect, useState, useRef } from 'react';
import Slide1 from '../../assets/images/slider/s1.jpg';
import Slide2 from '../../assets/images/slider/s2.jpg';
import Slide3 from '../../assets/images/slider/s3.jpg';
import Slide4 from '../../assets/images/slider/s4.png';

import MobileSlide1 from '../../assets/images/slider/mobile-slider/1.png';
import MobileSlide2 from '../../assets/images/slider/mobile-slider/2.png';
import MobileSlide3 from '../../assets/images/slider/mobile-slider/3.png';
import MobileSlide4 from '../../assets/images/slider/mobile-slider/4.png';

import './Slider.css'; // Create a separate CSS file for styling
import { NavLink } from "react-router-dom"

const Slider = () => {
    const [isClient, setIsClient] = useState(false);
    const carouselRef = useRef(null);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) {
        return null; // Render nothing on the server-side
    }

    return (
        <>
            <div className="desktop_sliderDiv">
                <div ref={carouselRef} id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExample" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <NavLink to="/sell-mobile" style={{ display: "unset" }}><img src={Slide1} className="d-block w-100" alt="First slide" /></NavLink>
                        </div>
                        <div className="carousel-item">
                            <NavLink to="/sell-mobile" style={{ display: "unset" }}> <img src={Slide2} className="d-block w-100" alt="Second slide" /></NavLink>
                        </div>
                        <div className="carousel-item">
                            <NavLink to="/sell-mobile" style={{ display: "unset" }}><img src={Slide3} className="d-block w-100" alt="Third slide" /></NavLink>
                        </div>
                        <div className="carousel-item">
                            <NavLink to="/partner-form" style={{ display: "unset" }}><img src={Slide4} className="d-block w-100" alt="Partner slide" /></NavLink>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            <div className="mobile_sliderDiv">
                <div id="carouselExampleMobile" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleMobile" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleMobile" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleMobile" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleMobile" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <NavLink to="/sell-mobile" style={{ display: "unset" }}><img src={MobileSlide1} className="d-block w-100" alt="Mobile First slide" /></NavLink>
                        </div>
                        <div className="carousel-item">
                            <NavLink to="/sell-mobile" style={{ display: "unset" }}> <img src={MobileSlide2} className="d-block w-100" alt="Mobile Second slide" /></NavLink>
                        </div>
                        <div className="carousel-item">
                            <NavLink to="/sell-mobile" style={{ display: "unset" }}><img src={MobileSlide3} className="d-block w-100" alt="Mobile Third slide" /></NavLink>
                        </div>
                        <div className="carousel-item">
                            <NavLink to="/partner-form" style={{ display: "unset" }}><img src={MobileSlide4} className="d-block w-100" alt="Mobile Partner slide" /></NavLink>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleMobile" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleMobile" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>


    );
};

export default Slider;
