import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { submitPartnerForm } from '../../utils/api';
import Loader from '../../components/loader/loader';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import './partner.css'
const PartnerForm = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        partnerFormik.setFieldValue(name, value);
    };

    const partnerFormik = useFormik({
        initialValues: {
            name: '', mobile: '', city: '', pincode: '', shop_name: '', gstin: '', message: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            mobile: Yup.string().required('Mobile is required').matches(/^[6-9]\d{9}$/, 'Mobile number is invalid'),
            city: Yup.string().required('City is required'),
            pincode: Yup.string().required('Pincode is required').matches(/^\d{6}$/, 'Pincode is invalid'),
            shop_name: Yup.string().required('Shop Name is required'),
            gstin: Yup.string(),
            message: Yup.string().max(300, 'Message must be at most 300 characters')
        }),

        onSubmit: async (values, { setSubmitting, setErrors }) => {
            console.log('Form values before API call:', values);

            setLoading(true);
            try {
                const response = await submitPartnerForm(values);
                console.log('API Response:', response); // Debug API response

                if (response?.success) {
                    console.log('Resetting form fields'); // Log before reset
                    partnerFormik.resetForm();
                    toast.success(response?.message);
                    // Delay for 2 seconds before refreshing the route
                    setTimeout(() => {
                        navigate(0); // Refresh the current route
                    }, 2000);


                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.error('API error:', error);
                toast.error("An error occurred while submitting the form."); // Optional: Provide user feedback on error
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        }

    });

    return (
        <>
            {loading && <Loader />}
            <section className="section-padding" id="partner-form">
                <div className="container">
                    <div className="justify-content-center">

                        <div className="section-title-wrap mt-4 mb-5">
                            <h4 className="section-title">Partner with Us</h4>
                        </div>
                        <div className="col-lg-12 col-12 mx-auto">
                            <form onSubmit={partnerFormik.handleSubmit} autoComplete={'off'} className="custom-form contact-form" role="form">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="name" id="name" className="form-control"
                                                placeholder="Name*" required="" onChange={handleInputChange} />

                                            <label htmlFor="name">Name*</label>
                                            {partnerFormik.touched.name && partnerFormik.errors.name ? (
                                                <div className="error">{partnerFormik.errors.name}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="mobile" id="mobile" className="form-control"
                                                placeholder="Mobile*" required="" onChange={handleInputChange} />

                                            <label htmlFor="mobile">Mobile*</label>
                                            {partnerFormik.touched.mobile && partnerFormik.errors.mobile ? (
                                                <div className="error">{partnerFormik.errors.mobile}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="city" id="city" className="form-control"
                                                placeholder="City*" required="" onChange={handleInputChange} />

                                            <label htmlFor="city">City*</label>
                                            {partnerFormik.touched.city && partnerFormik.errors.city ? (
                                                <div className="error">{partnerFormik.errors.city}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="pincode" id="pincode" className="form-control"
                                                placeholder="Pincode*" required="" onChange={handleInputChange} />

                                            <label htmlFor="pincode">Pincode*</label>
                                            {partnerFormik.touched.pincode && partnerFormik.errors.pincode ? (
                                                <div className="error">{partnerFormik.errors.pincode}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="shop_name" id="shop_name" className="form-control"
                                                placeholder="Shop Name*" required="" onChange={handleInputChange} />

                                            <label htmlFor="shop_name">Shop Name*</label>
                                            {partnerFormik.touched.shop_name && partnerFormik.errors.shop_name ? (
                                                <div className="error">{partnerFormik.errors.shop_name}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="form-floating">
                                            <input type="text" name="gstin" id="gstin" className="form-control"
                                                placeholder="GSTN*" required="" onChange={handleInputChange} />

                                            <label htmlFor="gstin">GSTIN</label>
                                            {partnerFormik.touched.gstin && partnerFormik.errors.gstin ? (
                                                <div className="error">{partnerFormik.errors.gstin}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <textarea className="form-control" id="message" name="message"
                                                placeholder="Message" onChange={handleInputChange}></textarea>

                                            <label htmlFor="floatingTextarea"> Message </label>
                                            <span className="error-message">
                                                {partnerFormik.touched.message && partnerFormik.errors.message ? (
                                                    <div className="error">{partnerFormik.errors.message}</div>
                                                ) : null}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-12 ms-auto">
                                        <button type="submit" className="form-control">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PartnerForm;
