import React, { useState } from 'react';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { fetchSeriesAndModel, getExactValue, insertPriceEnquiry } from '../../utils/api';
import { useEffect } from 'react';
import phone from '../../assets/images/sell-img.png';
import { modelImagePath } from '../../utils/constants';
import CardPaymentImg from '../../assets/images/icon/card-payment.png';
import PickUpImg from '../../assets/images/icon/pickup.png';
import RequestImg from '../../assets/images/icon/request-for-proposal.png';
import RecommendationImg from '../../assets/images/icon/recommendation.png';
import DiscountImg from '../../assets/images/icon/discount.png';
import './exactvalue.css';
import Loader from '../../components/loader/loader';
import DeviceReport from '../../components/device-report/DeviceReport';
import ReportModal from '../../components/report-modal/reportModal';
const Exactvalue = ({ title, content }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userData')));
    const [selectedModel, setSelectedModel] = useState(JSON.parse(localStorage.getItem('selectedModel')) || null);
    const [exactValue, setExactValue] = useState(null);
    const [finalValue, setFinalValue] = useState(null);
    const [insertFinalValue, setInsertFinalValue] = useState(null);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isopenModalDeviceReport, setIsopenModalDeviceReport] = useState(false);

    const openModalDeviceReport = () => {
        setIsopenModalDeviceReport(true);
    };
    const closeModal = () => {
        setIsopenModalDeviceReport(false);
    };
    const toggleAccordion2 = () => {
        setIsOpen2(!isOpen2);
    };

    function handleRecalculate() {
        localStorage.removeItem("isInserted");
        navigate(`/options/${selectedModel?.ic_slug}/${selectedModel?.variant}`);
    }

    useEffect(() => {
        const fetchDataAndInsert = async () => {
            setLoading(true);
            try {
                // Call fetchData first
                const response = await getExactValue(selectedModel);
                const formattedAmount = new Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: 'INR'
                }).format(response?.finalValue);

                setInsertFinalValue(response?.finalValue);
                setFinalValue(formattedAmount);
                setExactValue(response);

                // Now that we have the final value, proceed to insert data
                if (localStorage.getItem("isInserted") == null) {
                    const subObj = {
                        selectedModel: selectedModel,
                        user: userInfo,
                        finalValue: response?.finalValue
                    };
                    const insertResponse = await insertPriceEnquiry(subObj);
                    localStorage.setItem('isInserted', insertResponse);
                }
            } catch (error) {
                console.error('Error fetching sell mobile data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataAndInsert();
    }, [selectedModel, userInfo]);


    // useEffect(() => {

    //     const fetchData = async () => {
    //         setLoading(true);
    //         try {
    //             const response = await getExactValue(selectedModel);
    //             const formattedAmount = new Intl.NumberFormat('en-IN', {
    //                 style: 'currency',
    //                 currency: 'INR'
    //             }).format(response?.finalValue);
    //             setInsertFinalValue(response?.finalValue);
    //             setFinalValue(formattedAmount);
    //             setExactValue(response);
    //         } catch (error) {
    //             console.error('Error fetching sell mobile data:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();

    // }, []);


    // const inserPriceEnquiryData = async () => {
    //     const subObj = {
    //         selectedModel: selectedModel,
    //         user: userInfo,
    //         finalValue: insertFinalValue
    //     };
    //     const response = await insertPriceEnquiry(subObj);
    //     debugger;
    //     localStorage.setItem('isInserted', response);
    // };

    // useEffect(() => {

    //     if (insertFinalValue != null) {
    //         if (localStorage.getItem("isInserted") == null) {
    //             inserPriceEnquiryData();
    //         }
    //     }

    // }, [insertFinalValue]);

    function handleContinue() {
        localStorage.removeItem("isInserted");
        navigate('/sell-now');
    }

    return (
        <>
            {loading && <Loader />}
            <ReportModal isReportOpen={isopenModalDeviceReport} OnReportClose={closeModal} />

            <section className="about-section section-padding" id="section_2">
                <div className="container">
                    <div className='row justify-content-left mt-3'>
                        <div className="col-md-8 mt-0 mb-4">
                            <div className="custom-block1 ">
                                <div className='row'>
                                    <div className="col-md-4" >
                                        <div className="custom-block-icon-wrap" style={{ marginTop: "10%" }}>
                                            <div className="custom-block-image-wrap text-center">
                                                <img src={selectedModel?.item_image} alt="img" style={{ width: "auto", height: "150px", margin: "0 auto" }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-block-info col-md-8">
                                        <h5 className='pt-2'>{selectedModel?.model_name} {selectedModel?.variant_name}</h5>
                                        <p className='mb-2 mt-4'>
                                            <b>Selling price </b>
                                        </p>
                                        <div className="mt-2 ">
                                            <h3 className='' style={{ color: "#7e57c2" }}>
                                                <span>{finalValue}</span>
                                            </h3>
                                        </div>
                                        <button className="btn custom-btn recalculate" onClick={openModalDeviceReport}>
                                            See Device Report
                                        </button>
                                        <div className="mt-2 ">
                                            <span>Not satisfied with the evaluation? </span>
                                            <button className="btn custom-btn recalculate mt-2" onClick={() => handleRecalculate()}>
                                                Recalculate
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="custom-block1">
                                <div className="text_content">
                                    <h5>Price Summary</h5>
                                </div>
                                <div className="plan pt-2 ">
                                    <div className='orderPrice'>
                                        <div className='pt-2'>
                                            <p className='mb-0'>Base Price</p>
                                        </div>
                                        <p className='pt-0 mb-0'>{finalValue}</p>
                                    </div>

                                    <div className='orderPrice'>
                                        <div className='pt-2'>
                                            <p className='mb-0'> Pickup Charges</p>
                                        </div>
                                        <p className='pt-0 mb-0'>Free</p>
                                    </div>

                                    <div className='orderPrice totalAmountSumry'>
                                        <div className='pt-2'>
                                            <p className='mb-0'> <b>Total Amount</b></p>
                                        </div>
                                        <p className='pt-0 mb-0'> {finalValue}</p>
                                    </div>
                                </div>

                                <div className="payment-options mt-3" >
                                    <button type="button" className="btn custom-btn1 mb-3" onClick={() => handleContinue()} style={{ width: "100%" }}>Continue</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='mb-2 mt-4 Edflex'>
                                <div className='iconBoxSum '>
                                    <div className='row'>
                                        <div className='col-md-4 text-center'>
                                            <img src={RecommendationImg} className="img-fluid symIcons" alt="" />
                                        </div>
                                        <div className='col-md-8 text-alignment'>
                                            <span>Simple and Hassle-free</span>
                                        </div>
                                    </div>

                                </div>
                                <div className='iconBoxSum'>
                                    <div className='row'>
                                        <div className='col-md-4 text-center'>
                                            <img src={DiscountImg} className="img-fluid symIcons" alt="" />
                                        </div>
                                        <div className='col-md-8 text-alignment'>
                                            <span>Best Price Estimate - Instantly!</span>
                                        </div>
                                    </div>


                                </div>
                                <div className='iconBoxSum'>
                                    <div className='row'>
                                        <div className='col-md-4 text-center' >
                                            <img src={CardPaymentImg} className="img-fluid symIcons" alt="" />
                                        </div>
                                        <div className='col-md-8 text-alignment'>
                                            <span>Instant Money & Secure Transaction</span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className='pt-3  order-4 order-md-4'>
                                <p className='text-danger'><b>* Please note this amount is subject to change upon physical device inspection</b> </p>
                                {/* <p className='text-danger'><b>* If Device is Few Days Old , then Chat with us to get Better Price</b> </p> */}
                            </div>

                        </div>

                    </div>

                </div>

            </section>
            {/* <section className="about-section section-padding" style={{ paddingTop: "0px" }}>
                <div className="container">
                    <div className="accordion">
                        <div className="accordion-header" onClick={toggleAccordion2}>
                            <p>See Device report</p>
                            <div className={`arrow ${isOpen2 ? 'open' : ''}`}></div>
                        </div>
                        {isOpen2 && (
                            <div className="accordion-content">
                                <DeviceReport selectedModel={selectedModel} />
                            </div>
                        )}
                    </div>
                </div>
            </section> */}
        </>
    );
}

export default Exactvalue;