import React, { useState, useEffect } from 'react';
import MetroCitiesModal from '../metro-cities/MetroCitiesModal';
import { useLocation } from 'react-router-dom';
function TopHeader() {
    const [showModal, setShowModal] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null); // Default city
    const location = useLocation();
    // Function to handle closing the modal
    const handleCloseModal = () => {
        setShowModal(false);
    };

    // Function to handle city selection and save it in local storage
    const handleCitySelection = (city) => {
        setSelectedCity(city); // Update the selected city
        localStorage.setItem('selectedCity', city); // Save to local storage
        setShowModal(false); // Close the modal after selecting
    };


    // Retrieve selected city from local storage on component mount
    useEffect(() => {
        const savedCity = localStorage.getItem('selectedCity');
        if (savedCity) {
            setSelectedCity(savedCity);
        }
        else {
            setShowModal(true);
        }
    }, [location]); // Empty dependency array to run only once when component mounts

    return (
        <>
            <MetroCitiesModal
                showModal={showModal}
                onClose={handleCloseModal}
                onSelectCity={handleCitySelection}
            />
            <div className="container-fluid top-header-Desktop">
                <div className="row top-header py-2">
                    <div className="col-md-6">
                        <ul className="contact-info">
                            {/* <li><i className="bi bi-phone"></i> <a href="tel:9490990079" className="contactDetail">Phone: 9490990079</a></li> */}
                            <li><i className="bi bi-envelope"></i> <a href="mailto:mailcellcash.in@gmail.com" className="contactDetail">mailcellcash.in@gmail.com</a> </li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-end">
                        <ul className="contact-info">
                            <li>
                                <button className="btn custom-btn2 " onClick={() => setShowModal(true)}><i className="bi bi-geo-alt-fill me-1"></i>  {selectedCity} {/* Display the selected city here */}
                                    <i className="bi bi-chevron-down"></i></button>

                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className="container-fluid top-header-Mobile">
                <div className="row top-header py-2">
                    <div className="col-md-4 text-start" style={{ width: "40%" }}>
                        <ul className="contact-info">
                            <li> <a href="mailto:mailcellcash.in@gmail.com" className="contactDetail"><i className="bi bi-envelope" style={{ fontSize: "24px" }}></i></a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 text-end" style={{ width: "60%" }}>
                        <ul className="contact-info">
                            <li>
                                <button className="btn custom-btn2 " onClick={() => setShowModal(true)}><i className="bi bi-geo-alt-fill me-1"></i>  {selectedCity} {/* Display the selected city here */} <i className="bi bi-chevron-down"></i></button>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>


        </>
    )
}

export default TopHeader