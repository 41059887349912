import React, { useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getsalesorders } from '../../utils/api';
import { useEffect } from 'react';
import './salesorders.css';
import CardPaymentImg from '../../assets/images/icon/card-payment.png';
import PickUpImg from '../../assets/images/icon/pickup.png';
import RequestImg from '../../assets/images/icon/request-for-proposal.png';
import RecommendationImg from '../../assets/images/icon/recommendation.png';
import DiscountImg from '../../assets/images/icon/discount.png';
import AcceptedImg from '../../assets/images/icon/thumbsup.svg';
import PendingPymentImg from '../../assets/images/icon/pendingPyment.svg';
import SuccessImg from '../../assets/images/icon/success.svg';
import MobileImg from '../../assets/images/icon/mobile.svg';
import CloseImg from '../../assets/images/icon/cancel.svg';
import { modelImagePath } from '../../utils/constants';
import moment from 'moment';
import Loader from '../../components/loader/loader';
const SalesOrders = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userData')));
    console.log(userInfo);
    const [loading, setLoading] = useState(true);
    const [salesOrders, setSalesOrders] = useState([]);
    const { ref_no } = useParams();
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getsalesorders(userInfo?.customer_id);
                setSalesOrders(response?.salesorders);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching sell mobile data:', error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filteredSalesOrderBySearch = searchQuery
        ? salesOrders.filter((item) =>
            item.RefNo.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            item.Model.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            item.Status.toLowerCase().includes(searchQuery?.toLowerCase())
        )
        : salesOrders;

    return (

        <>
            {loading && <Loader />}
            <section className="about-section section-padding" id="section_2">
                <div className="container">
                    < div className="col-lg-12 col-12">
                        <div className="section-title-wrap mt-4 mb-3">
                            <h4 className="section-title">Sales Order</h4>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className="col-md-8">
                            <div className="col-lg-4 col-md-4 mb-3" style={{ margin: "0 0 0 auto" }}>
                                <form action="#" method="get" className="custom-form search-form flex-fill me-0 sales-order-serchInput" role="search">
                                    <div className="input-group input-group-lg">
                                        <input
                                            name="search"
                                            type="search"
                                            className="form-control"
                                            id="search"
                                            placeholder="Search For..."
                                            aria-label="Search"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </form>
                            </div>
                            {filteredSalesOrderBySearch.length > 0 && filteredSalesOrderBySearch.map((s, index) => (
                                <div className="custom-block" style={{ marginBottom: "10px", padding: "20px" }} key={index}>
                                    <div className='row' style={{ borderBottom: "1px solid #ccc" }}>
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <div className="col-10 mb-3">
                                                    <p className='mb-0'>
                                                        <span className='mr-5'>
                                                            {s?.Status == 'Accepted' && <img className='status-icon' src={AcceptedImg} />}
                                                            {s?.Status == 'Enquiry' && <img className='status-icon' src={PendingPymentImg} />}
                                                            {s?.Status == 'Cancelled' && <img className='status-icon' src={CloseImg} />}
                                                            {s?.Status == 'Out for Pick up' && <img className='status-icon' src={MobileImg} />}
                                                            {s?.Status == 'Completed' && <img className='status-icon' src={SuccessImg} />}
                                                        </span>
                                                        <b>{s.Status}</b>
                                                    </p>
                                                </div>
                                                <div className="col-2 text-right"><span className="badge">Sell</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-3" >
                                            <div className="custom-block-icon-wrap" style={{ marginTop: "10%" }}>
                                                <div className="custom-block-image-wrap text-center" style={{ padding: "10px" }}>
                                                    <img src={modelImagePath + s.ic_slug + '.webp'} alt="img" style={{ width: "auto", height: "100px", margin: "0 auto" }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="custom-block-info col-md-9">
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <p className='mt-4 mb-0'>{moment(s?.TransactionDate).format("MMMM Do, YYYY, h:mm A")}</p>
                                                    <p className='mt-0 mb-0'>{s?.Model}</p>
                                                    <p className='mb-2 mt-0'>{s?.VariantInfo.toUpperCase()}</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className="mt-4 ">
                                                        <p className='' >
                                                            <b>
                                                                <span>Sell Amount</span>
                                                                <span style={{ color: "#7e57c2" }}> {new Intl.NumberFormat('en-IN', {
                                                                    style: 'currency',
                                                                    currency: 'INR'
                                                                }).format(s?.ExactValue)}</span>
                                                            </b>

                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mt-1 text-center-sm">
                                                <button className="btn custom-btn recalculate" onClick={() => navigate('/sales-order-detail/' + s.RefNo)}>
                                                    View order details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {filteredSalesOrderBySearch.length == 0 &&
                                <div className="custom-block" style={{ marginBottom: "10px", padding: "24px" }} >
                                    <div className='row' >
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <h2>No Records Found.</h2>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                        <div className="col-md-4">
                            <div className="custom-block1" style={{ padding: "12px" }}>
                                <div className="text_content mt-3" style={{ borderBottom: "1px solid rgb(204, 204, 204)" }}>
                                    <h5>My Profile</h5>
                                </div>
                                <div className="plan pt-4 ">
                                    <div className='orderPrice'>
                                        <div className='pt-0'>
                                            <p className=''><b>{userInfo?.name}</b></p>
                                        </div>
                                    </div>
                                    <div className='orderPrice'>
                                        <div className='pt-0'>
                                            <p className=''><i className='bi bi-phone'></i> {userInfo?.mobile}</p>
                                        </div>
                                    </div>
                                    {userInfo?.email && <div className='orderPrice'>
                                        <div className='pt-0'>
                                            <p className=''><i className="bi bi-envelope"></i> {userInfo?.email}</p>
                                        </div>
                                    </div>}
                                    <div className="payment-options text-right">
                                        <button className='button-like-link me-3' onClick={() => { navigate('/editprofile') }}>Edit Profile <i className='bi bi-arrow-right'></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};

export default SalesOrders;
