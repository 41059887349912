import React, { useEffect, useState, useRef } from 'react';
import './checkoutModal.css';

import OtpInput from 'react-otp-input';
import { checkoutcustomer, customerLogin, customerOtpVerify, customerRegistration, fetchExistsCustomer, forgetpasswordcustomer, forgetpasswordverifycustomerotp, getcities, resendotpOTPRequest, sendForgetPasswordOTPRequest } from '../../utils/api';
import { fetchPincodes, addaddress, editaddress } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useUser } from '../../UserContext';

import Loader from '../loader/loader';


const CheckoutModal = ({ isOpen, onClose, onCheckoutModalSubmit, selectedModel = null, selectedaddress = null }) => {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('userData')));
    const dropdownRef = useRef(null);
    const [mobile, setMobile] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [step, setStep] = useState(1);
    const [token, setToken] = useState(null);
    const { user, logoutUser, loginUser } = useUser();
    const [respMessage, setRespMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [addressTypeValue, setAddressTypeValue] = useState("Home");
    const [localityValue, setLocalityValue] = useState('');
    const [cityValue, setCityValue] = useState('');
    const [stateValue, setStateValue] = useState('');
    const [pincodeValue, setPincodeValue] = useState('');
    const [pinCodeData, setPinCodeData] = useState([]);
    const [searchPinCode, setSearchPinCode] = useState([]);
    const savedCity = localStorage.getItem('selectedCity');
    const [cityData, setCityData] = useState([]);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSearchPinCode([]);
            setPincodeValue('');
        }
    };

    const fetchCity = async () => {
        try {
            const response = await getcities();
            const cities = response?.data || [];
            setCityData(cities);

            // Set default city from local storage if it exists
            const savedCity = localStorage.getItem('selectedCity');
            if (savedCity && cities.some(city => city.city_name === savedCity)) {
                checkoutFormik.setFieldValue('city', savedCity);
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        if (searchPinCode.length > 0) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [searchPinCode]);

    // const handlePinCodeChange = (event) => {
    //     const { name, value } = event.target;
    //     const filteredSuggestions = pinCodeData?.filter(item =>
    //         item?.pincode?.includes(value)
    //     );
    //     setSearchPinCode(filteredSuggestions);
    //     setPincodeValue(value);
    //     checkoutFormik.setFieldValue("pincodeValue", value);
    //     setLocalityValue('');
    //     setCityValue('');
    //     setStateValue('');
    //     checkoutFormik.setFieldValue("pincode", '');
    //     checkoutFormik.setFieldValue("state", '');
    //     checkoutFormik.setFieldValue("locality", '');
    //     checkoutFormik.setFieldValue("city", '');
    // };
    const handlePinCodeChange = (event) => {
        const { value } = event.target;

        const filteredSuggestions = pinCodeData?.filter((item) =>
            item?.pincode?.includes(value)
        );

        setSearchPinCode(filteredSuggestions); // Update filtered results
        setPincodeValue(value); // Update input value

        // Reset Formik fields related to location
        checkoutFormik.setFieldValue('pincodeValue', value);
        checkoutFormik.setFieldValue('pincode', '');
        checkoutFormik.setFieldValue('state', '');
        checkoutFormik.setFieldValue('locality', '');
    };

    const handlePinCodeClick = (value) => {
        checkoutFormik.setFieldValue("pincode", value?.pincode);
        checkoutFormik.setFieldValue("pincodeValue", value?.pincode);
        checkoutFormik.setFieldValue("state", value?.state);

        checkoutFormik.setFieldValue("locality", value?.locality);
        checkoutFormik.setFieldValue("city", value?.city);
        setPincodeValue(value?.pincode);
        setLocalityValue(value?.locality);
        setCityValue(value?.city);
        setStateValue(value?.state);
        setSearchPinCode([]);


    };

    const nextStep = () => {
        setStep(step + 1);
    };
    const prevStep = () => {
        setStep(step - 1);
    };


    //mobile, name, email, locality, city, state, pincode, platform
    //formik form handle submit
    const checkoutFormik = useFormik({
        initialValues: {
            mobile: mobile,
            name: name,
            email: email,
            locality: localityValue,
            city: cityValue,
            state: stateValue,
            pincode: '',
            pincodeValue: pincodeValue,
            platform: 'web'
        },
        validationSchema: Yup.object({
            mobile: Yup.string()
                .required('Mobile number is required')
                .matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
            name: Yup.string().required('Name is required'),
            email: Yup.string().matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Email is invalid'),
            city: Yup.string().required('City is required'),
            pincode: Yup.string().required('Pincode is required')
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                console.log(values);
                setMobile(values.mobile);
                const response = await checkoutcustomer(values.mobile, values.name, values.email,
                    values.locality, values.city, values.state, values.pincode, values.platform);
                if (response?.success) {
                    setStep(2);
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });



    //formik form handle login
    const verifyOtpFormik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().required('Otp is required'),
        }),
        onSubmit: async (values, { setSubmitting, setErrors }) => {
            try {
                const response = await customerOtpVerify(mobile, values.otp);
                if (response?.success) {
                    loginUser(response?.customer, response?.token);
                    setMobile(null);
                    setStep(1);
                    setOtp(null);
                    verifyOtpFormik.resetForm();
                    onCheckoutModalSubmit();
                } else {
                    toast.error(response?.message);
                }
            } catch (error) {
                console.error('API error:', error);
            }
            setSubmitting(false);
        }
    });

    const resendotpOTP = async () => {
        setLoading(true);
        try {
            const response = await resendotpOTPRequest(mobile);
            if (response?.success) {

            } else {

            }
            setLoading(false);
        } catch (error) {
            console.error('API error:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (stateValue) {
            checkoutFormik.setTouched({ ...checkoutFormik.touched, pincode: true });
            checkoutFormik.validateField('pincode');
        }
    }, [stateValue]);

    useEffect(() => {
        if (step == 1) {
            setMobile('');
        }
        if (step == 5) {
        }
    }, [step]);

    // const handleCityChange = async (event) => {
    //     const selectedCity = event.target.value;
    //     console.log(selectedCity)
    //     console.log(savedCity)
    //     // Save selected city to local storage (if needed)
    //     localStorage.setItem('selectedCity', selectedCity);

    //     // Update formik field value
    //     checkoutFormik.setFieldValue('city', selectedCity);

    //     // Reset related fields
    //     setPincodeValue('');
    //     setSearchPinCode([]);
    //     setPinCodeData([]); // Clear existing pincode data
    //     checkoutFormik.setFieldValue('pincode', '');

    //     // Fetch pincodes for the selected city
    //     try {
    //         setLoading(true);
    //         const response = await fetchPincodes(selectedCity);
    //         setPinCodeData(response?.pincodes || []);
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Error fetching pincodes:', error);
    //         setLoading(false);
    //     }
    // };

    const handleCityChange = async (event) => {
        const selectedCity = event.target.value;
        localStorage.setItem('selectedCity', selectedCity);
        // Update Formik and clear dependent fields
        checkoutFormik.setFieldValue('city', selectedCity);
        setPincodeValue('');
        setSearchPinCode([]);
        setPinCodeData([]); // Clear previous pincode data
        checkoutFormik.setFieldValue('pincode', '');

        // Fetch pincodes for the selected city
        try {
            setLoading(true);
            const response = await fetchPincodes(selectedCity); // Call the API
            setPinCodeData(response?.pincodes || []);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching pincodes:', error);
            setLoading(false);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchPincodes(savedCity);
                setPinCodeData(response?.pincodes);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        fetchData();
        fetchCity();
    }, []);
    return (
        <div className={`modal ${isOpen ? 'is-active' : ''}`} style={{ overflowY: 'auto' }}>
            <div className="modal-background" onClick={() => { setStep(1); onClose() }}></div>
            <div className="modal-content container">
                <div className='right pb-5'>
                    <button className="modal-close is-large" aria-label="close" onClick={() => { setStep(1); onClose() }}>
                        <i className="bi bi-x-circle close-icon-checkput"></i>
                    </button>
                </div>
                <div className="row box" style={{ padding: "20px" }}>
                    <div className="col-md-6 d-flex flex-column align-items-center text-center mt-4">
                        <div className="row">
                            <div className="col-12 text-start">
                                <h5 className="title">You’re just 1-step away</h5>
                                <p className='mb-40'>Fill in the form to discover our exclusive prices</p>
                            </div>
                            <div className="col-12">
                                <div>
                                    <img src={selectedModel?.item_image} alt="img" className="img-fluid" style={{ maxHeight: '101px', margin: '0 auto' }} />
                                </div>
                            </div>
                            <div className="col-12">
                                <p className='mb-0 mt-2'><b>{selectedModel?.model_name} {selectedModel?.variant_name}</b></p>
                                <h3 style={{ color: "#7e57c2" }}>
                                    <span><i className="bi bi-currency-rupee"></i>XX,XXX</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-2">
                        {step === 1 && (
                            <form onSubmit={checkoutFormik.handleSubmit} autoComplete="off" className="custom-form contact-form">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="mobile"
                                                placeholder="Mobile No"
                                                required=""
                                                onChange={checkoutFormik.handleChange}
                                                onBlur={checkoutFormik.handleBlur}
                                                value={checkoutFormik.values.mobile}
                                            />
                                            <label htmlFor="floatingInput">Mobile No</label>
                                            <span className="error-message">
                                                {checkoutFormik.touched.mobile && checkoutFormik.errors.mobile && (
                                                    <div className="error">{checkoutFormik.errors.mobile}</div>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                onChange={checkoutFormik.handleChange}
                                                onBlur={checkoutFormik.handleBlur}
                                                value={checkoutFormik.values.name}
                                            />
                                            <label htmlFor="floatingInput">Name</label>
                                            <span className="error-message">
                                                {checkoutFormik.touched.name && checkoutFormik.errors.name && (
                                                    <div className="error">{checkoutFormik.errors.name}</div>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="email"
                                                placeholder="Email"
                                                onChange={checkoutFormik.handleChange}
                                                onBlur={checkoutFormik.handleBlur}
                                                value={checkoutFormik.values.email}
                                            />
                                            <label htmlFor="floatingInput">Email</label>
                                            <span className="error-message">
                                                {checkoutFormik.touched.email && checkoutFormik.errors.email && (
                                                    <div className="error">{checkoutFormik.errors.email}</div>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <select
                                                className="form-select"
                                                name="city"
                                                onChange={handleCityChange} // Updated handler to fetch pincodes dynamically
                                                onBlur={checkoutFormik.handleBlur}
                                                value={checkoutFormik.values.city}
                                            >
                                                <option value="" disabled>
                                                    Select a city
                                                </option>
                                                {cityData.map((city, index) => (
                                                    <option key={index} value={city.city_name}>
                                                        {city.city_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect">City</label>
                                            <span className="error-message">
                                                {checkoutFormik.touched.city && checkoutFormik.errors.city && (
                                                    <div className="error">{checkoutFormik.errors.city}</div>
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating autocomplete checkout">
                                            <input
                                                name="pincode"
                                                id="pincode"
                                                type="hidden"
                                                value={checkoutFormik.values.pincode}
                                                onChange={checkoutFormik.handleChange}
                                                onBlur={checkoutFormik.handleBlur}
                                            />
                                            <input
                                                name="pincodeValue"
                                                id="pincodeValue"
                                                className="form-control"
                                                placeholder="Enter Pincode*"
                                                value={pincodeValue}
                                                onChange={(e) => handlePinCodeChange(e)}
                                                disabled={loading} // Disable input while loading pincodes
                                            />
                                            {loading && <p>Loading pincodes...</p>} {/* Show loading state */}
                                            <ul id="suggestions-list" className="autocomplete-dropdown" ref={dropdownRef}>
                                                {searchPinCode?.map((spincode, index) => (
                                                    <li key={index} onClick={() => handlePinCodeClick(spincode)}>
                                                        {spincode?.pincode}, {spincode?.city}, {spincode?.state}
                                                    </li>
                                                ))}
                                            </ul>
                                            <label htmlFor="pincode">Enter Pincode*</label>
                                            <span className="error-message">
                                                {checkoutFormik.errors.pincode && (
                                                    <div className="error">{checkoutFormik.errors.pincode}</div>
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating">
                                            <select
                                                className="form-select"
                                                name="city"
                                                onChange={handleCityChange} // Updated handler
                                                onBlur={checkoutFormik.handleBlur}
                                                value={checkoutFormik.values.city}
                                            >
                                                <option value="" disabled>
                                                    Select a city
                                                </option>
                                                {cityData.map((city, index) => (
                                                    <option key={index} value={city.city_name}>
                                                        {city.city_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <label htmlFor="floatingSelect">City</label>
                                            <span className="error-message">
                                                {checkoutFormik.touched.city && checkoutFormik.errors.city && (
                                                    <div className="error">{checkoutFormik.errors.city}</div>
                                                )}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-12">
                                        <div className="form-floating autocomplete checkout">
                                            <input
                                                name="pincode"
                                                id="pincode"
                                                type="hidden"
                                                value={checkoutFormik.values.pincode}
                                                onChange={checkoutFormik.handleChange}
                                                onBlur={checkoutFormik.handleBlur}
                                            />
                                            <input
                                                name="pincodeValue"
                                                id="pincodeValue"
                                                className="form-control"
                                                placeholder="Enter Pincode*"
                                                value={pincodeValue}
                                                onChange={(e) => handlePinCodeChange(e)}
                                            />
                                            <ul id="suggestions-list" className="autocomplete-dropdown" ref={dropdownRef}>
                                                {searchPinCode?.map((spincode, index) => (
                                                    <li key={index} onClick={() => handlePinCodeClick(spincode)}>
                                                        {spincode?.pincode}, {spincode?.city}, {spincode?.state}
                                                    </li>
                                                ))}
                                            </ul>
                                            <label htmlFor="pincode">Enter Pincode*</label>
                                            <span className="error-message">
                                                {checkoutFormik.errors.pincode && (
                                                    <div className="error">{checkoutFormik.errors.pincode}</div>
                                                )}
                                            </span>
                                        </div>
                                    </div> */}
                                    {/* {(cityValue && stateValue) && (
                                        <div className="col-lg-12 col-md-12 col-12">
                                            <p>{cityValue}, {stateValue}</p>
                                        </div>
                                    )} */}
                                    <div className="col-lg-6 col-12 m-auto mt-3">
                                        <button
                                            type="submit"
                                            className="form-control"
                                            disabled={checkoutFormik.isSubmitting}
                                        >
                                            {checkoutFormik.isSubmitting ? 'Processing...' : 'Proceed'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                        {step === 2 && (
                            <form onSubmit={verifyOtpFormik.handleSubmit} autoComplete="off" className="custom-form contact-form">
                                <div className="">
                                    <div style={{ marginBottom: '30px' }}>
                                        <h5 className="title">Enter OTP</h5>
                                        <p className="mb-40">A one-time password has been sent to {mobile}. <button className="button-like-link" onClick={() => { resendotpOTP(); }}>Resend</button></p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-12 opt">
                                            {/* <OtpInput
                                                className="inputStyle"
                                                numInputs={4}
                                                name="otp"
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                                value={verifyOtpFormik.values.otp}
                                                onChange={(otp) => verifyOtpFormik.setFieldValue('otp', otp)}
                                                onBlur={verifyOtpFormik.handleBlur('otp')}
                                            /> */}

                                            <OtpInput
                                                className="inputStyle"
                                                style={{ width: "3em" }}
                                                numInputs={4}
                                                name='otp'
                                                renderSeparator={<span>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                                value={verifyOtpFormik.values.otp}
                                                onChange={otp => {
                                                    verifyOtpFormik.setFieldValue('otp', otp);
                                                    if (otp.length === 4) {
                                                        verifyOtpFormik.submitForm();
                                                    }
                                                }}
                                                onBlur={verifyOtpFormik.handleBlur('otp')}
                                            />

                                            <span className="error-message">
                                                {verifyOtpFormik.touched.otp && verifyOtpFormik.errors.otp && (
                                                    <div className="error">{verifyOtpFormik.errors.otp}</div>
                                                )}
                                            </span>
                                        </div>
                                        <div className="col-lg-6 col-12 m-auto mt-5">
                                            <button type="submit" className="form-control">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default CheckoutModal;
